import {useAuthStore} from "~/stores/auth";

export default defineNuxtRouteMiddleware(async () => {
    const app = useNuxtApp()
    const store = await useAuthStore(app.$pinia)

    if (store.user === null) {
        return navigateTo('/login')
    }

    if (store.user === undefined) {
        await store.initAuth();

        const user = store.user;

        if (user === null) {
            return navigateTo('/login')
        }
    }
})